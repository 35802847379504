import styled from '@emotion/styled';
import Image from '@base/FutureImage';
import useWindowSize from '@hooks/useWindowSize';
import { isLocationUS } from '@api/selectors/geolocation';
import { mediaQuery, Spacings } from '@styles/index';
import { OS } from '@constants/support';
import segmentStorage from '@events/segmentStorage';
import { emptyObject } from '@constants/empty';
import useGeoLocation from '@api/hooks/useGeoLocation';
import About from './About';
import Download from './Download';
import ReachOut from './ReachOut';
import Copywrite from './Copywrite';
import Social from './Social';
import LinkGroup from './LinkGroup';
import Link from './Link';
import {
  helpAndSupportLinks,
  popularGoalLinks,
  studyMaterialLinks,
  EducatorAppLinks,
  unacademyCentreLinks
} from './constants';

const Container = styled.footer`
  width: 100%;
  margin: auto;
  background-color: var(--color-base-1);
  margin-top: ${Spacings.SPACING_HEADER_MARGIN}px;
  padding: ${Spacings.SPACING_20B} 0px ${Spacings.SPACING_10B} 0px;
  position: relative;

  ${mediaQuery.uptoTablet} {
    padding: ${Spacings.SPACING_10B} ${Spacings.SPACING_4B}
      ${Spacings.SPACING_10B} ${Spacings.SPACING_4B};
  }
`;

const AppImage = styled(Image)`
  border-radius: ${Spacings.SPACING_1B};
`;

const FlexLink = styled(Link)`
  display: flex;
  align-items: center;
`;

const LinkWithImage = styled(FlexLink)`
  margin-bottom: ${Spacings.SPACING_3B} !important;

  & > span {
    margin-left: ${Spacings.SPACING_2B};
  }
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 1136px;
  margin: auto;
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 16px;
`;

const Left = styled.div`
  grid-column: 1/11;

  ${mediaQuery.uptoMobile} {
    grid-column: 1/24;
  }
`;

const Right = styled.div`
  grid-column: 12/25;
  display: flex;
  flex-wrap: wrap;

  ${mediaQuery.uptoMobile} {
    grid-column: 1/24;
  }
`;

const Seperator = styled.hr`
  margin: ${({ $margin }) =>
    $margin ? `${$margin} !important` : `${Spacings.SPACING_6B} 0px`};
  background-color: var(--color-divider);
  height: 1px;
  border: none;
  width: ${({ $width }) => $width || '100%'};

  ${mediaQuery.uptoMobile} {
    margin: ${Spacings.SPACING_4B} 0px;
  }
`;

const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-column: span 24;

  ${mediaQuery.uptoMobile} {
    flex-direction: column-reverse;
  }
`;

const MarginBottom = styled.div`
  padding-top: ${Spacings.SPACING_9B};

  ${mediaQuery.uptoMobile} {
    padding-top: 0;
  }
`;

/* const Pill = styled.div`
  width: fit-content;
  border-radius: ${Spacings.SPACING_7B};
  padding: 1px 6px;
  color: var(--color-i-blue);
  background: var(--color-i-blue-27);
  margin-left: ${Spacings.SPACING_2B};
  margin-right: ${Spacings.SPACING_2B};
  font-weight: ${Fonts.SEMIBOLD};
  font-size: ${Fonts.EXTRA_SMALL};
`; */

const renderCompanyLinks = () => (
  <LinkGroup title="Company">
    <Link href="/about" isRel={false}>
      About us
    </Link>
    <Link href="/shikshodaya" isRel={false}>
      Shikshodaya
    </Link>
    <FlexLink href="/careers" isRel={false}>
      <span>Careers</span>
      {/* <Pill>we’re hiring</Pill> */}
    </FlexLink>
    <Link href="https://blog.unacademy.com">Blogs</Link>
    <Link href="/privacy" isRel={false}>
      Privacy Policy
    </Link>
    <Link href="/terms" isRel={false}>
      Terms and Conditions
    </Link>
  </LinkGroup>
);

const renderUSCompanyLinks = () => (
  <LinkGroup title="Company">
    <Link href="/about" isRel={false}>
      About us
    </Link>
    <Link href="/careers" isRel={false}>
      Careers
    </Link>
    <Link href="https://blog.unacademy.com">Blogs</Link>
    <Link href="/privacy" isRel={false}>
      Privacy Policy
    </Link>
    <Link href="/terms" isRel={false}>
      Terms and Conditions
    </Link>
    <Link href="/accessibility-statement" isRel={false}>
      Accessibility Statement
    </Link>
  </LinkGroup>
);

const renderHelpAndSupportLinks = () => (
  <LinkGroup title="Help & support">
    {helpAndSupportLinks.map(({ label, href }) => (
      <Link key={href} href={href} isRel={false}>
        {label}
      </Link>
    ))}
  </LinkGroup>
);

const renderProductsLinks = ({ isApppleProduct }) => (
  <LinkGroup title="Products">
    <LinkWithImage
      key="Learner app"
      href="https://unacademy.onelink.me/vSdS/054lnfro"
    >
      <AppImage
        src="footer/learner.svg"
        alt="Learner app"
        height={24}
        width={24}
      />
      <span>Learner app</span>
    </LinkWithImage>
    <LinkWithImage
      key="Educator app"
      href={
        isApppleProduct
          ? EducatorAppLinks.APP_STORE
          : EducatorAppLinks.PLAY_STORE
      }
    >
      <AppImage
        src="footer/educator.svg"
        alt="Educator app"
        height={24}
        width={24}
      />
      <span>Educator app</span>
    </LinkWithImage>
    <LinkWithImage
      key="Parent app"
      href="https://play.google.com/store/apps/details?id=com.unacademy.unacademyparentapp"
    >
      <AppImage
        src="footer/parent.svg"
        alt="Parent app"
        height={24}
        width={24}
      />
      <span>Parent app</span>
    </LinkWithImage>
  </LinkGroup>
);

const renderPopularGoalsLinks = () => (
  <LinkGroup title="Popular goals">
    {popularGoalLinks.map(({ label, href }) => (
      <Link key={href} href={href} isRel={false}>
        {label}
      </Link>
    ))}
  </LinkGroup>
);

const renderUnacademyCentreLinks = () => (
  <LinkGroup title="Unacademy Centre">
    {unacademyCentreLinks.map(({ label, href }) => (
      <Link key={href} href={`${href}?source=footer`} isRel={false}>
        {label}
      </Link>
    ))}
  </LinkGroup>
);

const renderStudymaterialsLinks = () => (
  <LinkGroup title="Study material">
    {studyMaterialLinks.map(({ label, href }) => (
      <Link key={href} href={href} isRel={false}>
        {label}
      </Link>
    ))}
  </LinkGroup>
);

const BaseFooter = ({
  display = false,
  noFooter = false,
  isUnIcons = false,
  hideProps = false
}) => {
  const { isMobile } = useWindowSize();
  const { geoLocationData } = useGeoLocation();
  const isUSLocation = isLocationUS(geoLocationData);
  const { os: systemOS } = segmentStorage.getItem('systemInfo', emptyObject);
  const isApppleProduct = systemOS === OS.MacOS || systemOS === OS.iOS;

  // TODO: remove this existing logic
  if (!display) return !noFooter ? <MarginBottom /> : null;

  return (
    <Container>
      <Wrapper>
        <Content>
          <Left>
            <About isUnIcons={isUnIcons} />
            <Download />
            {!hideProps && (
              <Seperator
                $width="40px"
                $margin={`${Spacings.SPACING_10B} 0px ${Spacings.SPACING_6B} 0px`}
              />
            )}
            {!hideProps && <ReachOut />}
          </Left>
          <Right>
            {isMobile && <Seperator $margin={`${Spacings.SPACING_10B} 0px`} />}
            {isUSLocation ? renderUSCompanyLinks() : renderCompanyLinks()}
            {renderHelpAndSupportLinks()}
            {renderProductsLinks({ isApppleProduct })}
            {!hideProps && renderPopularGoalsLinks()}
            {!hideProps && renderUnacademyCentreLinks()}
            {!hideProps && renderStudymaterialsLinks()}
          </Right>
        </Content>
        <Seperator />
        <Bottom>
          <Copywrite />
          <Social isUnIcons={isUnIcons} />
        </Bottom>
      </Wrapper>
    </Container>
  );
};

export default BaseFooter;
