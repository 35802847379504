import styled from '@emotion/styled';
import Logo from '@comp/Logo/Logo';
import P1 from '@base/P1/P1';
import { Spacings } from '@styles/index';

const Wrapper = styled.div`
  margin-bottom: ${Spacings.SPACING_6B};
`;

const Description = styled(P1)`
  width: 300px;
  margin-top: ${Spacings.SPACING_4B};
  color: var(--color-text-secondary);
`;

const About = ({ isUnIcons }) => {
  return (
    <Wrapper>
      <Logo type="footer" isUnIcons={isUnIcons} />
      <Description>
        Unacademy is India’s largest online learning platform. Download our apps
        to start learning
      </Description>
    </Wrapper>
  );
};

export default About;
