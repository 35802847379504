import P2 from '@base/P2/P2';

const Copywrite = () => {
  return (
    <P2 color="var(--color-text-secondary)">
      {`© ${new Date().getFullYear()} Sorting Hat Technologies Pvt Ltd`}
    </P2>
  );
};

export default Copywrite;
