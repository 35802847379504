import styled from '@emotion/styled';
import DownloadFromPlayStore from '@icon/DownloadFromPlayStore';
import DownloadFromAppStore from '@icon/DownloadFromAppStore';
import { Spacings } from '@styles/index';
import useAppInstallAnalytics from '@hooks/useAppInstallAnalytics';
import { LearnerAppLinks } from './constants';

const Wrapper = styled.div`
  margin-bottom: ${Spacings.SPACING_2B};
`;

const Link = styled.a`
  margin-right: ${Spacings.SPACING_2B};
`;

const RenderEducatorAppLinks = () => {
  const sendAapInstallAnalytics = useAppInstallAnalytics();
  const handleAppStoreIconClick = () => {
    sendAapInstallAnalytics({
      button_text: 'Download on the App Store'
    });
  };
  const handleGooglePlayIconClick = () => {
    sendAapInstallAnalytics({
      button_text: 'GET IT ON Google Play'
    });
  };

  return (
    <>
      <Link
        href={LearnerAppLinks.APP_STORE}
        target="_blank"
        rel="noopener noreferrer"
        onClick={handleAppStoreIconClick}
      >
        <DownloadFromAppStore height="34" width="100" viewBox="0 0 100 34" />
      </Link>
      <Link
        href={LearnerAppLinks.PLAY_STORE}
        target="_blank"
        rel="noopener noreferrer"
        onClick={handleGooglePlayIconClick}
      >
        <DownloadFromPlayStore height="35" width="116" viewBox="0 0 116 35" />
      </Link>
    </>
  );
};

const Download = () => {
  return (
    <Wrapper>
      <RenderEducatorAppLinks />
    </Wrapper>
  );
};

export default Download;
