import styled from '@emotion/styled';
import H6 from '@base/H6/H6';
import { Spacings, mediaQuery } from '@styles/index';

const Wrapper = styled.div`
  width: 33%;
  margin-bottom: ${Spacings.SPACING_10B};

  ${mediaQuery.uptoMobile} {
    width: 45%;
  }
`;

const Title = styled(H6)`
  margin-bottom: ${Spacings.SPACING_4B};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: ${Spacings.SPACING_2B};
  }
`;

const LinkGroup = ({ title, children }) => {
  return (
    <Wrapper>
      <Title>{title}</Title>
      <Content>{children}</Content>
    </Wrapper>
  );
};

export default LinkGroup;
