import PropTypes from 'prop-types';
import Icon from './Icon';

const Call = ({ color, ...rest }) => (
  <Icon color={color} {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.53591 9.40179C5.65841 8.52429 4.99691 7.54179 4.55741 6.54504C4.46441 6.33429 4.51916 6.08754 4.68191 5.92479L5.29616 5.31054C5.79941 4.80729 5.79941 4.09554 5.35991 3.65604L4.47941 2.77629C3.89366 2.19054 2.94416 2.19054 2.35841 2.77629L1.86941 3.26454C1.31366 3.82029 1.08191 4.62204 1.23191 5.41704C1.60241 7.37679 2.74091 9.52254 4.57766 11.3593C6.41441 13.196 8.56016 14.3345 10.5199 14.705C11.3149 14.855 12.1167 14.6233 12.6724 14.0675L13.1607 13.5793C13.7464 12.9935 13.7464 12.044 13.1607 11.4583L12.2809 10.5785C11.8414 10.139 11.1289 10.139 10.6902 10.5785L10.0129 11.2565C9.85016 11.4193 9.60341 11.474 9.39266 11.381C8.39591 10.9408 7.41341 10.2785 6.53591 9.40179Z"
      stroke={color}
      strokeWidth="1.125"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="transparent"
    />
    <path
      d="M8.68994 2.87753V1.12253"
      stroke={color}
      strokeWidth="1.125"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.8174 4.11501L13.0549 2.88501"
      stroke={color}
      strokeWidth="1.125"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.0625 7.25006H14.81"
      stroke={color}
      strokeWidth="1.125"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);

export default Call;

Call.propTypes = {
  color: PropTypes.string.isRequired
};
