import { segment } from '@actions/analytics';
import { APP_INSTALL_CTA_CLICKED } from '@constants/segment';
import { useRouter } from 'next/router';

const useAppInstallAnalytics = () => {
  const { asPath: path } = useRouter();
  return (additionalProps = {}) => {
    segment.track(APP_INSTALL_CTA_CLICKED, {
      Page_Url: path,
      ...additionalProps
    });
  };
};

export default useAppInstallAnalytics;
