import Icon from './Icon';

const DownloadFromPlayStore = (props) => (
  <Icon {...props}>
    <path
      d="M92.0304 0.268755H7.96733C7.66091 0.268755 7.35817 0.268755 7.05256 0.270432C6.79674 0.272108 6.54295 0.276979 6.28467 0.281078C5.72356 0.287702 5.16378 0.337225 4.61018 0.429214C4.05735 0.523207 3.52185 0.70041 3.02177 0.954829C2.52231 1.2114 2.06593 1.54479 1.6692 1.9429C1.27039 2.33989 0.937948 2.79876 0.684662 3.30186C0.430677 3.80396 0.254563 4.34201 0.162402 4.89742C0.0693653 5.45213 0.0193014 6.01323 0.0126596 6.5757C0.00490507 6.83272 0.00408616 7.0906 0 7.34766V26.7244C0.00408616 26.9847 0.00490507 27.2368 0.0126596 27.4972C0.0193034 28.0596 0.0693673 28.6207 0.162402 29.1754C0.254308 29.7311 0.430433 30.2695 0.684662 30.7718C0.937833 31.2732 1.27032 31.7303 1.6692 32.125C2.06443 32.5249 2.5211 32.8585 3.02177 33.1131C3.52184 33.3682 4.05729 33.5465 4.61018 33.6419C5.16387 33.7332 5.7236 33.7827 6.28467 33.7902C6.54295 33.7959 6.79674 33.7991 7.05256 33.7991C7.35816 33.8008 7.66093 33.8008 7.96733 33.8008H92.0304C92.3307 33.8008 92.6359 33.8008 92.9362 33.7991C93.1908 33.7991 93.452 33.7959 93.7066 33.7902C94.2666 33.7831 94.8252 33.7336 95.3778 33.6419C95.9325 33.5458 96.4698 33.3676 96.9723 33.1131C97.4725 32.8584 97.9288 32.5248 98.3237 32.125C98.7215 31.7287 99.0547 31.272 99.311 30.7718C99.5634 30.2691 99.7378 29.7308 99.8284 29.1754C99.9216 28.6206 99.9734 28.0597 99.9835 27.4972C99.9867 27.2368 99.9867 26.9847 99.9867 26.7244C99.9932 26.4198 99.9932 26.1169 99.9932 25.8074V8.2629C99.9932 7.95591 99.9932 7.65137 99.9867 7.34766C99.9867 7.0906 99.9867 6.83272 99.9835 6.57566C99.9734 6.01315 99.9216 5.45217 99.8284 4.89739C99.7376 4.3423 99.5631 3.80429 99.311 3.30183C98.7952 2.29342 97.9773 1.47258 96.9723 0.954754C96.4698 0.700956 95.9324 0.5238 95.3778 0.429139C94.8253 0.336744 94.2666 0.287204 93.7066 0.280961C93.452 0.27687 93.1908 0.271958 92.9362 0.270323C92.6359 0.268646 92.3307 0.268646 92.0304 0.268646V0.268755Z"
      fill="black"
    />
    <path
      d="M7.0595 33.0659C6.8049 33.0659 6.55645 33.0627 6.30386 33.057C5.78058 33.0501 5.25852 33.0044 4.74197 32.9202C4.26032 32.837 3.79372 32.6822 3.35757 32.4609C2.92543 32.2415 2.53128 31.9537 2.19022 31.6087C1.84422 31.2678 1.55617 30.8723 1.33746 30.438C1.11635 30.0009 0.963328 29.5324 0.883724 29.0488C0.79776 28.5291 0.751249 28.0037 0.744594 27.477C0.739296 27.3002 0.732361 26.7115 0.732361 26.7115V7.3463C0.732361 7.3463 0.739748 6.76669 0.744636 6.59641C0.751008 6.07053 0.797248 5.54589 0.882956 5.02705C0.962706 4.54211 1.11585 4.07224 1.33708 3.63371C1.55498 3.19973 1.84144 2.80398 2.18535 2.46181C2.52887 2.11638 2.92428 1.82715 3.35716 1.60467C3.79231 1.3841 4.25802 1.23039 4.73871 1.14868C5.25695 1.06365 5.78082 1.01768 6.30591 1.01115L7.05991 1.00092H92.935L93.698 1.01156C94.2183 1.01776 94.7374 1.06333 95.2509 1.14786C95.7364 1.23059 96.207 1.38537 96.6471 1.60712C97.5143 2.05549 98.22 2.76484 98.6651 3.63575C98.8828 4.07126 99.0336 4.53729 99.1123 5.01805C99.1991 5.54115 99.2476 6.0699 99.2576 6.6001C99.26 6.8375 99.26 7.09251 99.26 7.3463C99.2666 7.66067 99.2666 7.95988 99.2666 8.26155V25.8061C99.2666 26.1106 99.2666 26.4078 99.26 26.7074C99.26 26.98 99.26 27.2298 99.2567 27.4868C99.247 28.0075 99.1993 28.5268 99.1139 29.0406C99.036 29.5277 98.8838 29.9999 98.6627 30.4405C98.4424 30.8701 98.156 31.2621 97.814 31.6022C97.4727 31.9491 97.0778 32.2385 96.6447 32.4593C96.2058 32.6823 95.7359 32.8377 95.2509 32.9202C94.7344 33.0049 94.2123 33.0506 93.6889 33.057C93.4442 33.0627 93.1879 33.0659 92.9391 33.0659L92.0333 33.0676L7.0595 33.0659Z"
      fill="black"
    />
    <path
      d="M20.7001 17.2929C20.7183 15.8707 21.4615 14.5571 22.6693 13.8122C21.9039 12.7156 20.6679 12.0453 19.3342 12.0034C17.931 11.8556 16.5706 12.8458 15.8556 12.8458C15.1267 12.8458 14.0258 12.0181 12.8403 12.0425C11.2804 12.0931 9.86152 12.9612 9.10263 14.3293C7.48655 17.1363 8.692 21.2616 10.2401 23.5306C11.0146 24.6416 11.9198 25.8827 13.1043 25.8387C14.2633 25.7905 14.6962 25.0972 16.0953 25.0972C17.4813 25.0972 17.8875 25.8387 19.096 25.8107C20.3397 25.7904 21.1233 24.6947 21.8706 23.5732C22.4271 22.7815 22.8554 21.9065 23.1394 20.9807C21.6623 20.3539 20.7018 18.9019 20.7001 17.2929V17.2929Z"
      fill="white"
    />
    <path
      d="M18.4167 10.5092C19.0948 9.69249 19.4289 8.64278 19.348 7.58298C18.312 7.69214 17.355 8.18888 16.6677 8.97423C15.989 9.74908 15.6452 10.7628 15.712 11.7921C16.7619 11.8029 17.7588 11.3301 18.4167 10.5092V10.5092Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.3969 23.0222H35.3522L36.3012 25.8359H38.0051L34.2587 15.4259H32.5181L28.7717 25.8359H30.447L31.3969 23.0222ZM34.944 21.7213H31.8088L33.3552 17.1548H33.3985L34.944 21.7213Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.9377 25.916C44.8366 25.916 46.0949 24.4006 46.0949 22.0421C46.0949 19.6901 44.8292 18.1674 42.9091 18.1674C41.9211 18.1354 40.9956 18.651 40.5002 19.5092H40.4717V18.2468H38.9685V28.3473H40.5214V24.5881H40.5573C41.0316 25.4517 41.9562 25.9675 42.9377 25.916ZM42.5023 19.4943C43.71 19.4943 44.5016 20.5046 44.5016 22.0412C44.5016 23.5917 43.71 24.5945 42.5023 24.5945C41.3158 24.5945 40.5177 23.5704 40.5177 22.0412C40.5177 20.5258 41.3158 19.4943 42.5023 19.4943Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.2632 25.9159C53.1621 25.9159 54.4204 24.4006 54.4204 22.0421C54.4204 19.6901 53.1548 18.1674 51.2346 18.1674C50.2466 18.1354 49.3211 18.6509 48.8257 19.5092H48.7971V18.2468H47.294V28.3473H48.8469V24.5881H48.8828C49.3571 25.4517 50.2817 25.9675 51.2632 25.9159ZM50.8249 19.4943C52.0326 19.4943 52.8242 20.5046 52.8242 22.0412H52.8242C52.8242 23.5917 52.0326 24.5945 50.8249 24.5945C49.6384 24.5945 48.8403 23.5704 48.8403 22.0412C48.8403 20.5258 49.6384 19.4943 50.8249 19.4943Z"
      fill="white"
    />
    <path
      d="M59.926 22.9353C60.0411 23.9677 61.0407 24.6455 62.4068 24.6455C63.7157 24.6455 64.6574 23.9676 64.6574 23.0368C64.6574 22.2288 64.0894 21.745 62.7446 21.4134L61.3998 21.0884C59.4943 20.6267 58.6098 19.7327 58.6098 18.2821C58.6098 16.486 60.17 15.2522 62.3855 15.2522C64.5782 15.2522 66.0813 16.486 66.1319 18.2821H64.5643C64.4705 17.2432 63.6145 16.6161 62.3635 16.6161C61.1125 16.6161 60.2565 17.2506 60.2565 18.174C60.2565 18.91 60.8032 19.3431 62.1407 19.6746L63.284 19.9562C65.413 20.4613 66.2976 21.3193 66.2976 22.842C66.2976 24.7895 64.7512 26.0093 62.2917 26.0093C59.9905 26.0093 58.4367 24.8182 58.3364 22.9353L59.926 22.9353Z"
      fill="white"
    />
    <path
      d="M69.6496 16.4526V18.2487H71.0882V19.4824H69.6496V23.6666C69.6496 24.3166 69.9376 24.6195 70.5701 24.6195C70.7409 24.6165 70.9114 24.6045 71.0809 24.5834V25.8098C70.7966 25.8631 70.5076 25.8872 70.2183 25.8818C68.6867 25.8818 68.0893 25.3047 68.0893 23.8327V19.4824H66.9893V18.2487H68.0893V16.4526H69.6496Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M75.51 18.1527C73.3239 18.1527 71.9219 19.6533 71.9219 22.0413C71.9219 24.4358 73.31 25.9299 75.51 25.9299C77.7109 25.9299 79.0989 24.4358 79.0989 22.0413C79.0989 19.6532 77.7035 18.1527 75.51 18.1527ZM75.5114 19.4357C76.7697 19.4357 77.518 20.4025 77.518 22.0407H77.5179C77.5179 23.6853 76.7697 24.6448 75.5114 24.6448C74.253 24.6448 73.5056 23.6853 73.5056 22.0407C73.5056 20.4099 74.253 19.4357 75.5114 19.4357Z"
      fill="white"
    />
    <path
      d="M80.3805 18.2467H81.8616V19.5386H81.8975C82.1041 18.707 82.8636 18.1346 83.7173 18.1673C83.8962 18.1667 84.0747 18.1862 84.2493 18.2254V19.6826C84.0234 19.6134 83.7877 19.5816 83.5516 19.5885C83.0951 19.5699 82.6532 19.7525 82.3421 20.0882C82.031 20.4239 81.8817 20.8792 81.9334 21.3347V25.8365H80.3805L80.3805 18.2467Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M88.1463 25.9299C89.8575 25.9299 91.195 24.9843 91.4039 23.6065L89.9293 23.6065C89.6463 24.3108 88.9358 24.7449 88.1822 24.6741C87.6171 24.6949 87.0704 24.4713 86.681 24.0599C86.2916 23.6486 86.0971 23.0893 86.147 22.5243V22.4301H91.4904V21.8964C91.4904 19.5951 90.1741 18.1527 88.081 18.1527C85.9528 18.1527 84.5794 19.6966 84.5794 22.0773C84.5794 24.4506 85.9454 25.9299 88.1463 25.9299ZM89.9362 21.344L86.1539 21.344C86.1526 20.8316 86.3554 20.3398 86.7172 19.9781C87.0789 19.6164 87.5698 19.4146 88.0806 19.4177C88.5873 19.4068 89.0757 19.6084 89.4279 19.9741C89.7802 20.3398 89.9644 20.8363 89.9362 21.344Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.3693 8.27631C32.9149 7.79486 32.2691 7.54323 31.6099 7.59069H29.8089V12.5927H31.6099C33.0951 12.5927 33.956 11.6741 33.956 10.0761C34.0391 9.41831 33.8238 8.75777 33.3693 8.27631ZM31.524 11.8866H30.584V8.29882H31.524C31.9927 8.27273 32.4484 8.45811 32.7666 8.80425C33.0848 9.1504 33.2321 9.62109 33.1683 10.0876C33.2372 10.556 33.0919 11.0306 32.7729 11.3796C32.4539 11.7286 31.9952 11.9148 31.524 11.8866Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.6654 9.01664C35.0897 9.37502 34.7663 10.0298 34.8308 10.7064C34.7651 11.3838 35.0882 12.0398 35.6644 12.399C36.2406 12.7582 36.97 12.7582 37.5462 12.399C38.1224 12.0398 38.4455 11.3838 38.3797 10.7064C38.4443 10.0298 38.1209 9.37502 37.5452 9.01664C36.9695 8.65825 36.2411 8.65825 35.6654 9.01664ZM36.606 9.40758C37.249 9.40758 37.6154 9.88608 37.6154 10.7043H37.6154C37.6154 11.5258 37.249 12.0039 36.606 12.0039C35.9605 12.0039 35.5974 11.5291 35.5974 10.7043C35.5974 9.88608 35.9605 9.40758 36.606 9.40758Z"
      fill="white"
    />
    <path
      d="M43.0983 12.5895H42.328L41.5503 9.80937H41.4916L40.7172 12.5895H39.9542L38.917 8.81471H39.6702L40.3442 11.6951H40.3997L41.1733 8.81471H41.8857L42.6593 11.6951H42.7181L43.3888 8.81471H44.1314L43.0983 12.5895Z"
      fill="white"
    />
    <path
      d="M45.0012 8.81662H45.716V9.41628H45.7715C45.9645 8.97477 46.4154 8.7047 46.8944 8.74376C47.2652 8.71578 47.6286 8.85851 47.882 9.13164C48.1354 9.40477 48.2512 9.77866 48.1967 10.1477V12.5914H47.4542V10.3348C47.4542 9.7282 47.1914 9.42652 46.6422 9.42652C46.3907 9.41477 46.1465 9.51381 45.9738 9.69767C45.8011 9.88154 45.7171 10.1319 45.7437 10.3831V12.5914H45.0012L45.0012 8.81662Z"
      fill="white"
    />
    <path d="M49.3798 7.34183H50.1224V12.5902H49.3798V7.34183Z" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.9864 9.01664C51.4107 9.37503 51.0872 10.0299 51.1517 10.7065C51.086 11.3839 51.4092 12.0399 51.9854 12.3991C52.5617 12.7582 53.2911 12.7582 53.8673 12.3991C54.4435 12.0399 54.7667 11.3839 54.701 10.7065C54.7655 10.0299 54.4421 9.37503 53.8663 9.01664C53.2906 8.65825 52.5621 8.65825 51.9864 9.01664ZM52.9273 9.40758C53.5703 9.40758 53.9367 9.88608 53.9367 10.7043H53.9368C53.9368 11.5258 53.5704 12.0039 52.9273 12.0039C52.2819 12.0039 51.9187 11.5291 51.9187 10.7043C51.9187 9.88608 52.2818 9.40758 52.9273 9.40758Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56.8868 10.3984C55.9916 10.4541 55.4873 10.8458 55.4873 11.5253C55.4886 11.8475 55.6256 12.1541 55.8646 12.3693C56.1035 12.5845 56.4221 12.6883 56.7415 12.655C57.1972 12.6761 57.629 12.4497 57.8718 12.0623H57.9305V12.5928H58.6453V10.0136C58.6453 9.21626 58.1133 8.74513 57.17 8.74513C56.3164 8.74513 55.7085 9.16101 55.6326 9.80938H56.3515C56.4339 9.5425 56.7212 9.38982 57.1357 9.38982C57.6433 9.38982 57.906 9.61495 57.906 10.0136V10.3395L56.8868 10.3984ZM57.9089 10.8859V11.2015C57.8927 11.4421 57.7798 11.6657 57.5961 11.8213C57.4125 11.9768 57.1737 12.0509 56.9346 12.0267C56.5306 12.0267 56.2368 11.829 56.2368 11.4892C56.2368 11.1564 56.4719 10.9796 56.99 10.9448L57.9089 10.8859Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M61.1819 8.75552C60.2312 8.75552 59.62 9.51114 59.62 10.7039C59.62 11.8996 60.2247 12.6552 61.1819 12.6552C61.6672 12.6731 62.1223 12.4194 62.3635 11.9966H62.4223V12.5929H63.1338V7.34456H62.3912V9.41778H62.3357C62.1115 8.99138 61.6619 8.73333 61.1819 8.75552ZM61.3914 11.9859C60.7622 11.9859 60.386 11.5041 60.386 10.7035H60.386C60.386 9.90938 60.7663 9.42433 61.3914 9.42433C62.0132 9.42433 62.4041 9.92003 62.4041 10.7068C62.4041 11.4972 62.0173 11.9859 61.3914 11.9859Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67.0404 9.01664C66.4647 9.37502 66.1413 10.0298 66.2059 10.7064C66.1401 11.3838 66.4632 12.0398 67.0394 12.399C67.6156 12.7582 68.3451 12.7582 68.9212 12.399C69.4974 12.0398 69.8205 11.3838 69.7548 10.7064C69.8193 10.0298 69.4959 9.37502 68.9202 9.01664C68.3445 8.65825 67.6161 8.65825 67.0404 9.01664ZM67.9829 9.40758C68.626 9.40758 68.9924 9.88608 68.9924 10.7043C68.9924 11.5258 68.626 12.0039 67.9829 12.0039C67.3375 12.0039 66.9743 11.5291 66.9743 10.7043C66.9743 9.88608 67.3375 9.40758 67.9829 9.40758Z"
      fill="white"
    />
    <path
      d="M70.7544 8.81662H71.4692V9.41628H71.5247C71.7177 8.97477 72.1686 8.7047 72.6476 8.74376C73.0185 8.71578 73.3819 8.85851 73.6352 9.13164C73.8886 9.40477 74.0044 9.77866 73.95 10.1477V12.5914H73.2074V10.3348C73.2074 9.7282 72.9446 9.42652 72.3954 9.42652C72.1439 9.41477 71.8998 9.51381 71.7271 9.69767C71.5544 9.88154 71.4703 10.1319 71.497 10.3831V12.5914H70.7544V8.81662Z"
      fill="white"
    />
    <path
      d="M78.1431 7.87633V8.83333H78.9583V9.46083H78.1431V11.4019C78.1431 11.7973 78.3055 11.9704 78.6752 11.9704C78.7698 11.9701 78.8643 11.9644 78.9583 11.9532V12.5738C78.825 12.5977 78.6899 12.6104 78.5544 12.6118C77.7286 12.6118 77.3997 12.3204 77.3997 11.5926V9.4608H76.8024V8.8333H77.3997V7.87633H78.1431Z"
      fill="white"
    />
    <path
      d="M79.9714 7.34183H80.7075V9.42203H80.7662C80.9685 8.97666 81.4273 8.70624 81.9136 8.74582C82.2823 8.72569 82.6408 8.87156 82.8914 9.1437C83.142 9.41584 83.2586 9.7859 83.2094 10.1531V12.5902H82.4661V10.3369C82.4661 9.73393 82.1861 9.42857 81.6615 9.42857C81.4031 9.40731 81.1486 9.50154 80.9659 9.68605C80.7833 9.87056 80.6911 10.1265 80.7141 10.3856V12.5902H79.9715L79.9714 7.34183Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M85.9084 12.6684C86.6438 12.7484 87.3306 12.2884 87.5389 11.5764L86.824 11.5763C86.6458 11.8969 86.2921 12.0777 85.9288 12.034C85.6487 12.0371 85.3802 11.9215 85.1894 11.7156C84.9986 11.5097 84.9032 11.2327 84.9268 10.9526V10.9108H87.5838V10.6509C87.5838 9.4655 86.9521 8.74791 85.9052 8.74791C85.4022 8.74361 84.922 8.95848 84.5892 9.33685C84.2563 9.71522 84.1035 10.2199 84.1703 10.72C84.1017 11.2188 84.2551 11.7227 84.5898 12.0979C84.9245 12.4731 85.4067 12.6817 85.9084 12.6684ZM86.8275 10.3587H84.927C84.9236 10.0984 85.0252 9.84782 85.2086 9.6637C85.3921 9.47959 85.6418 9.3776 85.9013 9.38087C86.1573 9.37498 86.4039 9.47782 86.5803 9.66404C86.7566 9.85027 86.8464 10.1026 86.8275 10.3587Z"
      fill="white"
    />
  </Icon>
);

export default DownloadFromPlayStore;
