import styled from '@emotion/styled';
import { Spacings } from '@styles/index';
import { ICONS_INSTAGRAM } from '@constants/unacademyIcons/icons.constants';
import { socialIconsLinks } from './constants';

const SocialImage = styled.div`
  margin-right: ${Spacings.SPACING_3B};
  user-drag: none;
  user-select: none;
  width: ${Spacings.SPACING_6B};
  height: ${Spacings.SPACING_6B};
  border-radius: ${Spacings.SPACING_2B};
  transition: background-color 200ms ease;

  &:hover {
    background-color: var(--color-divider);
  }
`;

const Wrapper = styled.div`
  margin-bottom: ${Spacings.SPACING_4B};
  display: flex;
`;

const Social = ({ isUnIcons }) => {
  return (
    <Wrapper>
      {socialIconsLinks.map(({ icon: Icon, name, link }) => {
        const socialLink =
          isUnIcons && name === 'instagram' ? ICONS_INSTAGRAM : link;

        return (
          <a
            href={socialLink}
            key={name}
            rel="noopener noreferrer"
            target="_blank"
          >
            <SocialImage>
              <Icon color="var(--color-text-primary)" />
            </SocialImage>
          </a>
        );
      })}
    </Wrapper>
  );
};

export default Social;
