import styled from '@emotion/styled';
import H5 from '@base/H5/H5';
import P1 from '@base/P1/P1';
import Call from '@icon/Call';
import { Spacings } from '@styles/index';

const Description = styled(P1)`
  width: 300px;
  color: var(--color-text-secondary);
  margin-bottom: ${Spacings.SPACING_3B};
`;

const Phone = styled.a`
  display: flex;
  width: fit-content;
`;

const ReachOut = () => {
  return (
    <>
      <H5>Starting your preparation?</H5>
      <Description>
        Call us and we will answer all your questions about learning on
        Unacademy
      </Description>
      <Phone href="tel:+91 8585858585">
        <Call
          size={Spacings.SPACING_7B}
          viewBox="0 0 24 24"
          color="var(--color-text-primary)"
        />
        <H5>Call +91 8585858585 </H5>
      </Phone>
    </>
  );
};

export default ReachOut;
